.title{
    background: -webkit-linear-gradient(45deg,
        #FFDC80 10%,
        #FCAF45 20%,
        #F77737 30%,
        #F56040 40%,
        #FD1D1D 50%,
        #E1306C 60%,
        #C13584 70%,
        #833AB4 80%,
        #5851DB 90%,
        #405DE6 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
/*Scroll down - circle button*/
.btn-circle {
  /* width: 75px !important;
  height: 75px !important;
  padding: 10px 10px !important;
  border: none !important;
  border-radius: 100% !important;
  font-size: 100px !important; */
  color: rgb(255, 255, 255);
  background: 0 0;
  -webkit-transition: background .3s ease-in-out;
  -moz-transition: background .3s ease-in-out;
  transition: background .3s ease-in-out;
}
/*Animating the circle button*/ 
.scroll-down {
  /* -webkit-border-radius:50%;
  -moz-border-radius:50%;
  -ms-border-radius:50%; */
  /* border-radius:50%; */
  animation: Circlebounce 2s infinite;
  -webkit-animation: Circlebounce 2s infinite;
  -moz-animation: Circlebounce 2s infinite;
  -o-animation: Circlebounce 2s infinite;
}

@-webkit-keyframes Circlebounce {
0%, 20%, 50%, 80%, 100% {-webkit-transform: translateY(0);} 
40% {-webkit-transform: translateY(-30px);}
60% {-webkit-transform: translateY(-15px);}
}

@-moz-keyframes Circlebounce {
0%, 20%, 50%, 80%, 100% {-moz-transform: translateY(0);}
40% {-moz-transform: translateY(-30px);}
60% {-moz-transform: translateY(-15px);}
}

@-o-keyframes Circlebounce {
0%, 20%, 50%, 80%, 100% {-o-transform: translateY(0);}
40% {-o-transform: translateY(-30px);}
60% {-o-transform: translateY(-15px);}
}
@keyframes Circlebounce {
0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
40% {transform: translateY(-30px);}
60% {transform: translateY(-15px);}
}